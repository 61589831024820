<template>
	<div class="cw-app-wrapper promotion">
		<!--components-->
		<div class="cw-app-module">
			<MStepper />
		</div>
		<!--//components-->

		<!--contents-->
		<div class="cw-app-section">
			<div class="u-order-wrap payment">
				<!-- 결제정보 선택 요약 -->
				<div class="u-order-list head">
					<div class="itembox">
						<div class="tit_area">
							<h1 class="tit_page">결제정보 선택</h1>
						</div>
						<ul class="pay_tit">
							<li>
								<div><strong>정기결제금액</strong> 매월 납부하는 금액</div>
								<span>{{ totalAmt.totalSumOfMonthAmt | comma }}원/월</span>
							</li>
							<li>
								<div><strong>수납 금액</strong> 일회성 결제 금액</div>
								<span>{{ totalAmt.totalSumOfRecpAmt | comma }}원</span>
							</li>
						</ul>
						<!-- 고객입력 정기/수납 결제수단 정보 -->
						<div class="u-customer-pay" v-if="getMainPaymentInfo().result">
							<v-expansion-panels>
								<v-expansion-panel>
									<v-expansion-panel-header>
										<h3 class="title"><i>고객입력</i><em>결제 수단</em></h3>
									</v-expansion-panel-header>
									<v-expansion-panel-content>
										<ul class="list">
											<li>
												<em>
													{{ getMainPaymentInfo().name }}
													{{ getMainPaymentInfo().cardNo }}</em
												>
											</li>
										</ul>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</div>
						<!--// 고객입력 정기/수납 결제수단 정보 -->
					</div>
				</div>
				<!--// 결제정보 선택 요약 -->

				<!-- 정기결제수단 -->
				<div class="u-order-list">
					<div class="itembox" v-if="totalAmt.totalSumOfMonthAmt > 0">
						<h3 class="tit_page">정기결제수단</h3>
						<v-form class="form">
							<!-- 정기결제수단 적용방법 선택 -->
							<v-radio-group
								v-model="group"
								v-if="prdList.length > 1 && paymentLimitCommon === false"
								@change="selectEmpty"
							>
								<v-radio label="공통적용" value="1"></v-radio>
								<v-radio label="개별적용" class="ml-8" value="2"></v-radio>
							</v-radio-group>
							<!--// 정기결제수단 적용방법 선택 -->
							<!-- 공통적용 -->
							<ul class="promotion_order" v-if="group === '1'">
								<li>
									<div class="select">
										<div class="wrap_input">
											<PopPaymentBottomSheet
												title="결제정보"
												:paymentGroup="group"
												v-model="regularPaymentType[0]"
												:onceNo="regularNo[0]"
												:items="regularPaymentList"
												@item-selected="regularPaymentSelected($event, 0)"
												:addButton="true"
												:once="false"
												:paymentType="'recp'"
												:paymentLimit="
													paymentLimit > 0 ? paymentLimit[0].LIMIT_CHECK : ''
												"
												@getPaymentList="getPaymentList($event, 0)"
												:array="0"
												:disabled="
													paymentLimit > 0 &&
														paymentLimit[0].LIMIT_CHECK === 'X'
												"
											>
												<template>
													<span class="value">{{
														regularDisplayText[0] === undefined
															? '정기결제 수단 선택'
															: regularDisplayText[0]
													}}</span>
													<em class="arrow"></em>
												</template>
											</PopPaymentBottomSheet>
										</div>
									</div>
								</li>
							</ul>
							<!--// 공통적용 -->
							<!-- 개별적용 -->
							<ul class="promotion_order" v-else>
								<li v-for="(item, i) in prdList" :key="i">
									<div class="flex_row">
										<h4 class="promotion_order_tit">주문{{ i + 1 }}</h4>
										<ul>
											<li>
												<v-chip color="black" outlined small>
													{{ item.options.sellType.TEXT }}
												</v-chip>
												<span class="ml-2" v-if="item.options.agreeTerm"
													>{{ item.options.agreeTerm.USE_DUTY_MONTH }}개월</span
												>
											</li>
											<li>{{ item.prd.leader_model_nm }}</li>
											<li>{{ item.prd.prod_nm }}, {{ item.prd.erp_cd }}</li>
											<li>{{ item.MONTH_AMT | comma }}원/월</li>
										</ul>
									</div>
									<div class="select">
										<p
											class="message"
											v-if="
												paymentLimit.length > 0 &&
													paymentLimit[i].LIMIT_CHECK === 'X'
											"
										>
											주문불가
										</p>
										<p
											class="message"
											v-if="
												paymentLimit.length > 0 &&
													paymentLimit[i].LIMIT_CHECK === 'B'
											"
										>
											은행이체만가능
										</p>
										<p
											class="message"
											v-if="
												paymentLimit.length > 0 &&
													paymentLimit[i].LIMIT_CHECK === 'C'
											"
										>
											카드이체만가능
										</p>
										<div
											class="wrap_input"
											@click="onAlert(paymentLimit[i].LIMIT_CHECK)"
										>
											<!--결제정보-->
											<PopPaymentBottomSheet
												title="결제정보"
												:paymentGroup="group"
												v-model="regularPaymentType[i]"
												:onceNo="regularNo[i]"
												:items="regularPaymentList"
												@item-selected="regularPaymentSelected($event, i)"
												:addButton="true"
												:once="false"
												:paymentType="'recp'"
												:paymentLimit="
													paymentLimit.length === 0 ||
													paymentLimit[i].LIMIT_CHECK === undefined
														? null
														: paymentLimit[i].LIMIT_CHECK
												"
												@getPaymentList="getPaymentList($event, i)"
												:array="i"
												:disabled="
													paymentLimit.length > 0 &&
														paymentLimit[i].LIMIT_CHECK === 'X'
												"
											>
												<template>
													<span class="value">{{
														regularDisplayText[i] === undefined
															? '정기결제 수단 선택'
															: regularDisplayText[i]
													}}</span>
													<em class="arrow"></em>
												</template>
											</PopPaymentBottomSheet>
										</div>
									</div>
								</li>
							</ul>
							<!--// 개별적용 -->
						</v-form>
					</div>
				</div>
				<!--// 정기결제수단 -->

				<!-- 수납결제수단 -->
				<div class="u-order-list" v-if="totalAmt.totalSumOfRecpAmt > 0">
					<div class="itembox">
						<h3 class="tit_page">수납결제수단</h3>
						<div class="select">
							<div class="wrap_input">
								<!--결제정보-->
								<PopPaymentBottomSheet
									title="결제정보"
									v-model="receivePaymentType"
									:onceNo="receiveNo"
									:items="receivePaymentList"
									@item-selected="receivePaymentSelected"
									:addButton="true"
									:once="true"
									:paymentType="'recp'"
									@getPaymentList="getPaymentList($event, -1)"
								>
									<template>
										<span class="value"> {{ receiveDisplayText }}</span>
										<em class="arrow"></em>
									</template>
								</PopPaymentBottomSheet>
							</div>
						</div>
						<div class="select">
							<div
								class="wrap_input"
								v-if="this.receiveSelectPayment.payMthd === '12'"
							>
								<!--카드할부기간-->
								<PopPaymentBottomSheet
									title="카드할부 기간"
									v-model="receiveMonthlyType"
									:onceNo="receiveNo"
									:items="insMonthItems"
									@item-selected="receiveSelecteMonthly"
								>
									<template>
										<span class="value">{{ receiveMonthlyDisplayText }}</span>
										<em class="arrow"></em>
									</template>
								</PopPaymentBottomSheet>
							</div>
						</div>
					</div>
				</div>
				<!--// 수납결제수단 -->
			</div>
		</div>
		<!--//contents-->

		<!--bottom-->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" depressed large @click="back">
						이전
					</v-btn>
				</div>
				<div class="column">
					<v-btn
						class="u-btn-active"
						depressed
						large
						:disabled="false"
						@click="goRecp"
					>
						다음
					</v-btn>
				</div>
			</div>
		</div>
		<CustomRecpDialog ref="recpPop" />
		<!--//bottom-->
	</div>
</template>

<script>
/**
 * 결제정보 선택
 * @description 결제정보 선택 화면이다.
 *              WebDB에 저장된(orderInfoJson.PAY_SUMMARY_AMT) 값을 기준으로 화면을 활성화 한다.
 *              totalSumOfMonthAmt : 매월 정기적으로 결제해야할 금액이 있는 경우
 *              totalSumOfRecpAmt : 수납 결제가 있는 경우
 */
import { paymentInfo } from '@/apis/order.recp.api'
import filters from '@/mixins/filters'
import { getInsMonthOfCard } from '@/utils'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById,
	paymentList,
	mOrderInfoByEtc
} from '@/apis/order.status.api'
import {
	cardTransfer,
	bankInstantTransfer,
	custPayDelete
} from '@/apis/order.recp.api'
import { fetchPernrLimitCheck } from '@/apis/order.verify.api'
import { registerCallbackToApp } from '@/utils/navigation'
import { getMessageByStatusCode } from '@/utils/ec'
import { getPreContractSalesChk } from '@/apis/pre.contract.api'
import moment from 'moment'
import { isParameterEmpty } from '@/utils/common'

export default {
	name: 'RecpMethodPage',
	mixins: [filters],
	computed: {
		// 카드 할부 개월수
		insMonthItems() {
			let items = getInsMonthOfCard()
			return items
		}
	},
	data() {
		return {
			/**
			 * 공통
			 */
			//====================================================
			webOrderNo: this.$store.getters['verify/mobOrderNo'],
			prdList: [], // 주문 상품 리스트, 전체 데이터
			payOrders: [], // 주문 상품 리스트, 상품 필수 데이터
			paymentLimit: [], // 결제수단 제한 (X : 전체, C: 카드제한, B: 은행이체 제한)
			paymentLimitCommon: false, // 결제수단 제한 공통용
			//====================================================

			/**
			 * 정기결제 수단
			 */
			//====================================================
			group: '1', // 정기결제 수단 적용 방법 (공통적용 : 1, 개별적용: 2)
			regularPaymentList: [], // 정기결제 정보 리스트
			regularSelectPayment: [], // 선택된 정기결제 정보
			regularNo: [], // 선택된 정기결제 정보 - 구조 변경 필요
			regularPaymentType: [], // 선택된 정기결제수단 코드 리스트
			regularDisplayText: [], // 정기결제수단 출력 텍스트
			regularMainPayment: [], // 정기결제수단 메인 (고입확 대표결제수단)
			//====================================================

			/**
			 * 수납결제 수단
			 */
			//====================================================
			receivePaymentList: [], // 수납결제 정보 리스트
			receiveSelectPayment: {}, // 선택된 수납결제 정보
			receiveNo: '', // 선택된 수납결제 정보 - 구조 변경 필요
			receivePaymentType: '', // 선택된 수납결제수단 코드
			receiveDisplayText: '수납결제 수단 선택', // 수납결제수단 출력 텍스트
			receiveMonthlyType: '00', // 카드할부 개월수
			receiveMonthlyDisplayText: '할부기간 - 일시불', // 카드할부 출력 문구
			receiveMainPayment: [], // 수납결제수단 메인 (고입확 일회성결제수단)
			//====================================================

			// 기타, 스토어 저장 또는 주문 생성시 사용할 데이터로 보여짐
			recpInfos: {},
			totalAmt: {},
			payRecp: {},
			tranceRtn: '',
			//====================================================

			isPreContract: this.$store.state['verify'].customer.orderType === 'C',
			deliveryInfos: this.$store.getters['schedule/deliveryInfos']
			// payment list에서 MAIN_PAYMENT로 설정된 경우 선택될때 사용
			// MAIN_PAYMENT가 있을 경우 regularNo, receiveNo 에 카드 번호를 추가하여 선택이 되도록 변경 필요
		}
	},

	async created() {
		this.initAppPage()

		const mobOrderNo = this.$store.getters['verify/mobOrderNo']
		if (this.isParameterEmpty(mobOrderNo)) return

		this.recpInfos = this.$store.getters['recp/recpInfos']
		await this.getPaymentList()

		const prdListTemp = this.$store.getters['pay/payProducts']
		this.payOrders = this.$store.getters['pay/paymentOrders']

		this.regularSelectPayment = []
		this.regularDisplayText = []
		this.regularPaymentType = []
		this.regularNo = []

		// 정기 결제 금액이 있는 상품들만 추출하여 prdList에 추가
		prdListTemp.forEach((v, i) => {
			if (this.payOrders[i].MONTH_AMT !== 0) {
				this.prdList.push({
					// 정기결제 금액
					MONTH_AMT: this.payOrders[i].MONTH_AMT,
					...v
				})
				// 기본값 생성(빈값)
				this.regularNo.push('')
				this.regularPaymentType.push('')
			}
		})

		const res = await fetchWebOrderInfoById(this.webOrderNo)
		const json = JSON.parse(res.resultObject.orderInfoJson)
		this.totalAmt = json.PAY_SUMMARY_AMT

		// 고객직접인증 결제방법 선택, 판매인에 따른 결제수단 제한
		const T_INPUT = []
		this.prdList.forEach(v => {
			const ET_OUTPUT = v.payOptions.ET_INPUT
			T_INPUT.push({
				SEQ: ET_OUTPUT.I_SEQ,
				GOODS_CD: ET_OUTPUT.GOODS_CD,
				CONTRACT_ID: ET_OUTPUT.CONTRACT_ID,
				INS_MONTH: ET_OUTPUT.INS_MONTH
			})
		})

		const params = {
			type: '2', // 상품정보 검색 포함
			codyNo: this.$store.getters['common/codyNo'],
			T_INPUT: T_INPUT
		}

		const limitCheckRes = await fetchPernrLimitCheck(params)
		const pernrLimitCheck = limitCheckRes.resultObject.data

		if (pernrLimitCheck.E_RETURN.TYPE === 'T') {
			// T_OUTPUT 데이터가 있을 경우 적용
			if (pernrLimitCheck.T_OUTPUT.length > 0) {
				this.paymentLimit = pernrLimitCheck.T_OUTPUT
			} else {
				await this.prdList.forEach(v => {
					const ET_OUTPUT = v.payOptions.ET_INPUT
					this.paymentLimit.push({
						SEQ: ET_OUTPUT.I_SEQ,
						GOODS_CD: ET_OUTPUT.GOODS_CD,
						CONTRACT_ID: ET_OUTPUT.CONTRACT_ID,
						INS_MONTH: ET_OUTPUT.INS_MONTH,
						LIMIT_CHECK: '' // 결제수단 제한 (X : 전체, C: 카드이체 허용, B: 은행이체 허용)
					})
				})
			}
		} else {
			this.$alert({
				message: `${pernrLimitCheck.E_RETURN.MESSAGE}`
			})
		}

		let limitCount = 0
		await this.paymentLimit.forEach(v => {
			if (
				v.LIMIT_CHECK === 'X' ||
				v.LIMIT_CHECK === 'C' ||
				v.LIMIT_CHECK === 'B'
			) {
				limitCount += 1
			}
		})

		// 1개라도 제한이면 개별적용 디폴트, 공통,개별 선택 숨김
		if (limitCount > 0) {
			this.paymentLimitCommon = true
			this.group = '2'
		}

		// 대표 정기결제 수단
		const regulDe = this.regularPaymentList.filter(x => x.MAIN_PAYMENT)
		if (regulDe) {
			const result = this.getMainPayment(regulDe)
			this.regularMainPayment = {
				...result
			}
		}

		// 대표 수납결제 수단
		const onceDe = this.receivePaymentList.filter(
			x => x.MAIN_PAYMENT && x.PAY_TYPE === 'O'
		)
		if (onceDe) {
			const result = this.getMainPayment(onceDe)
			this.receiveMainPayment = {
				...result
			}
		}

		await this.defaultPaymentSetting()
	},

	async mounted() {},

	methods: {
		getMessageByStatusCode,
		isParameterEmpty,
		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},

		async cbPhysicalBack() {
			this.back()
		},

		// 대표결제수단정보
		getMainPaymentInfo() {
			if (this.regularMainPayment.result) {
				return this.regularMainPayment
			} else {
				return this.receiveMainPayment
			}
		},

		// 전체 제한의 경우 Alert
		onAlert(value) {
			if (value === 'X') {
				this.$alert({
					message:
						'조직 연체율로 인해 자체할부 주문이 불가합니다. 해당 주문은 이전 단계로 되돌아가 삭제하시기 바랍니다.'
				})
			}
		},

		/**
		 * 고입확에서 입력한 결제정보 확인
		 * @description 고입확에서 입력한 대표결제수단(정기), 일회성결제수단(수납)이 있으면 정보를 리턴
		 * @param paymentList 결제정보 리스트
		 * @returns {Promise<{MAIN_PAYMENT: boolean, AF_BANK_CD_NM: string, AF_CARD_NO: string}>}
		 */
		getMainPayment(paymentList) {
			// 기본 응답값
			let result = {
				result: false,
				name: '',
				cardNo: ''
			}

			paymentList.forEach(v => {
				if (v.MAIN_PAYMENT && v.MAIN_PAYMENT === true) {
					let cardNo = ''
					if (v.AF_PAY_MTHD === '11') {
						// 은행이체
						cardNo = this.$options.filters.star(v.AF_CARD_NO, 4)
					} else if (v.AF_PAY_MTHD === '12') {
						// 카드이체
						cardNo = this.$options.filters.star(v.AF_CARD_NO, 6)
					}

					result = {
						result: v.MAIN_PAYMENT,
						name: v.AF_BANK_CD_NM,
						cardNo: cardNo
					}
				}
			})
			return result
		},
		// 선택한 정기결제 수단 적용
		regularPaymentSelected(item, i) {
			if (item) {
				const json = {
					PAY_MTHD: item.AF_PAY_MTHD,
					CARDCOM_CD: item.AF_BANK_CD,
					CARD_NO: item.AF_CARD_NO,
					EXPIRE_YM: item.AF_EXPIRE_YM,
					KATR2: item.KATR2,
					OWNER_NM: item.AF_OWNER_NM,
					SSN_NO: item.AF_SSN_NO,
					PAY_DD: item.AF_PAY_DD,
					PAY_CUST_ID: this.$store.state['verify'].customer?.custNo || '',

					CMS_YN: 'Y',
					CARDCOM_NM: item.AF_BANK_CD_NM
				}
				this.regularDisplayText[i] = item.text
				this.regularSelectPayment[i] = json
				this.regularPaymentType[i] = item.code
				this.regularNo[i] = item.AF_CARD_NO
			} else {
				this.regularDisplayText[i] = '정기결제 수단 선택'
				this.regularSelectPayment[i] = undefined
				this.regularPaymentType[i] = undefined
				this.regularNo[i] = undefined
			}
		},

		// 선택된 수납결제 수단 적용
		receivePaymentSelected(item) {
			this.receiveDisplayText = item.text
			this.receiveSelectPayment = {}
			this.receivePaymentType = item.code
			this.receiveNo = item.AF_CARD_NO
			this.payRecp = { ...item }

			//카드이체시
			if (item.AF_PAY_MTHD === '12') {
				this.receiveSelectPayment.avDat = item.AF_EXPIRE_YM
				this.receiveSelectPayment.bankNm = item.AF_BANK_CD_NM
				this.receiveSelectPayment.bankc = item.AF_BANK_CD
				this.receiveSelectPayment.cardn = item.AF_CARD_NO
				this.receiveSelectPayment.depositor = item.AF_OWNER_NM
				this.receiveSelectPayment.kunnr = this.$store.getters['verify/custNo']
				this.receiveSelectPayment.mobOrderNo = this.$store.getters[
					'verify/mobOrderNo'
				]
				this.receiveSelectPayment.name = item.AF_OWNER_NM

				this.receiveSelectPayment.okamt = this.totalAmt.totalSumOfRecpAmt
				this.receiveSelectPayment.payMthd = item.AF_PAY_MTHD
				this.receiveSelectPayment.phone = this.$store.getters[
					'verify/custPhone'
				]
				this.receiveSelectPayment.restry =
					this.$store.getters['verify/customerType'] === 'L' ? '2' : '1'
				this.receiveSelectPayment.ssn = item.AF_SSN_NO
				this.receiveSelectPayment.userId = this.$store.getters['common/codyNo']
				this.receiveSelectPayment.zwebRecp = '00'
				this.receiveSelectPayment.insMon = '00'
			} else {
				this.receiveSelectPayment.bankNm = item.AF_BANK_CD_NM
				this.receiveSelectPayment.bankc = item.AF_BANK_CD
				this.receiveSelectPayment.suacc = item.AF_CARD_NO
				this.receiveSelectPayment.resty =
					this.$store.getters['verify/customerType'] === 'L' ? '2' : '1'
				this.receiveSelectPayment.resId = item.AF_SSN_NO
				this.receiveSelectPayment.depositor = item.AF_OWNER_NM

				this.receiveSelectPayment.okamt = this.totalAmt.totalSumOfRecpAmt
				this.receiveSelectPayment.userId = this.$store.getters['common/codyNo']
				this.receiveSelectPayment.cellPhone = this.$store.getters[
					'verify/custPhone'
				]
				this.receiveSelectPayment.kunnr = this.$store.getters['verify/custNo']
				this.receiveSelectPayment.mobOrderNo = this.$store.getters[
					'verify/mobOrderNo'
				]
				this.receiveSelectPayment.payMthd = '11'
				this.receiveSelectPayment.zwebRecp = '04'
			}
		},

		// [수납결제] 카드할부기간
		receiveSelecteMonthly(item) {
			this.receiveMonthlyDisplayText = '할부기간 - ' + item.text
			this.receiveSelectPayment.insMon = item.code
		},

		accountText(d) {
			let div = ''
			if (this.nameChk(d)) {
				if (d.AF_OWNER_NM.length > 10) {
					div = d.AF_OWNER_NM.substring(0, 10)
				} else {
					div = d.AF_OWNER_NM
				}
			} else {
				div = d.AF_PAY_MTHD === '11' ? '은행이체' : '카드이체'
			}

			//디버깅용
			// switch (d.AF_PAY_MTHD) {
			// 	case '11':
			// 		return `${div} : ${d.AF_BANK_CD_NM} ${d.AF_CARD_NO}`
			// 	case '12':
			// 		return `${div} : ${d.AF_BANK_CD_NM} ${d.AF_CARD_NO}`
			// }

			switch (d.AF_PAY_MTHD) {
				case '11':
					return `${div} : ${d.AF_BANK_CD_NM} ${this.$options.filters.star(
						d.AF_CARD_NO,
						4
					)}`
				case '12':
					return `${div} : ${d.AF_BANK_CD_NM} ${this.$options.filters.star(
						d.AF_CARD_NO,
						6
					)}`
			}
			return d.text
		},

		nameChk(d) {
			const name = this.$store.getters['verify/custName']
			if (name !== d.AF_OWNER_NM) {
				return true
			} else {
				return false
			}
		},

		/**
		 * 결제정보 리스트 호출
		 * @param paymentEvent 삭제한 결제수단 정보
		 * @param paymentType 결제 타입 (수납: -1, 정기: 그외 )
		 * @description 결제정보 추가 화면 진입시 저장된 결제정보 리스트를 호출하여 가져온다.
		 */
		async getPaymentList(params, prdIdx) {
			this.$log('prdIdx', prdIdx)
			let item = ''
			let type = ''
			let index = ''

			if (params) {
				item = params.item
				type = params.type
				index = params.index
			}

			// 결제수단 삭제
			let receivePaymentInfo = ''
			let regularPaymentInfo = ''

			if (type === 'DEL') {
				// 수납결제 결제번호
				this.receivePaymentList.forEach(v => {
					if (v.AF_CARD_NO === item) {
						receivePaymentInfo = v
					}
				})
				// 정기결제 결제번호
				this.regularPaymentList.forEach(v => {
					if (v.AF_CARD_NO === item) {
						regularPaymentInfo = v
					}
				})
			}

			// 2. 삭제할 PAYMENT_ID 추출, 삭제
			const paymentIds = []
			if (receivePaymentInfo !== '') {
				paymentIds.push(receivePaymentInfo.PAYMENT_ID)
			}
			if (regularPaymentInfo !== '') {
				paymentIds.push(regularPaymentInfo.PAYMENT_ID)
			}

			const webOrderNo = this.$store.getters['verify/mobOrderNo']
			const payDeleteRes = await custPayDelete(webOrderNo, {
				ids: paymentIds
			})

			const paymentDeleteRes = payDeleteRes.resultObject.data.TYPE

			// 3. 삭제완료 후 선택된 결제정보와 동일하면 초기화 처리
			if (type === 'DEL' && paymentDeleteRes === 'T') {
				if (receivePaymentInfo !== '' && item === this.payRecp.AF_CARD_NO) {
					// 선택된 수납결제 수단 초기화
					this.receiveDisplayText = '수납결제 수단 선택'
					this.receiveSelectPayment = {}
					this.receivePaymentType = ''
					this.receiveNo = ''
					this.payRecp = {}
				}
			}

			// TODO: 고입확에서 결제수단으로 사용할 수 없는 경우 빈값이 들어가는 케이스가 있음
			// TODO: 빈값으로 들어오는 경우 노출이 안되도록 설정
			// TODO: 고입확 결제 수단 체크 및 등록, 결제정보 선택 체크 및 등록 개선 필요
			// 결제정보 다시 호출
			const res = await paymentInfo(this.webOrderNo, { zwebRecp: '04' })
			this.regularPaymentList = res.resultObject.data.PAYMENT_LIST.filter(
				x => x.PAY_TYPE === 'R' && x.AF_CARD_NO !== ''
			).map(x => {
				return {
					code: x.AF_BANK_CD,
					text: this.accountText(x),
					nameChk: !x.SAP_YN,
					...x
				}
			})

			this.receivePaymentList = res.resultObject.data.PAYMENT_LIST.filter(
				x => x.PAY_TYPE === 'O' && x.AF_CARD_NO !== ''
			).map(x => {
				return {
					code: x.AF_BANK_CD,
					text: this.accountText(x),
					nameChk: !x.SAP_YN,
					...x
				}
			})

			if (type === 'DEL') {
				// 수납결제 삭제의 경우 마지막 정보로 추가
				if (this.receivePaymentList.length > 0) {
					this.receivePaymentSelected(
						this.receivePaymentList[this.receivePaymentList.length - 1]
					)
				}

				// 정기결제 삭제의 경우 마지막 정보로 추가
				if (this.prdList.length > 0) {
					// 공통 : prdIdx 는 0
					// 개별 : prdIdx 추출 필요

					const prdIndexs = []

					// 삭제한 결제 정보와 선택된 정기결제 정보가 동일한 배열 index 추출
					this.regularNo.forEach((v, i) => {
						if (v === item) {
							prdIndexs.push(i)
						}
					})

					// 삭제하는 결제 수단과 선택된 결제 수단이 동일한 경우 초기화
					prdIndexs.forEach(v => {
						this.regularDisplayText[v] = '정기결제 수단 선택'
						this.regularSelectPayment[v] = ''
						this.regularPaymentType[v] = ''
						this.regularNo[v] = ''
					})

					// 카드, 은행, 전체에 대한 마지막 결제수단 index 추출
					let paymentBankLastIdx = ''
					let paymentCardLastIdx = ''
					let paymentLastIdx = ''

					this.regularPaymentList.forEach((v, i) => {
						if (v.AF_PAY_MTHD === '12') {
							// 카드이체
							paymentCardLastIdx = i
						} else if (v.AF_PAY_MTHD === '11') {
							// 은행이체
							paymentBankLastIdx = i
						}
						paymentLastIdx = i
					})

					// 마지막 결제 수단으로 세팅
					prdIndexs.forEach(v => {
						if (this.paymentLimit[v].LIMIT_CHECK === 'C') {
							// 카드허용
							this.regularPaymentSelected(
								this.regularPaymentList[paymentCardLastIdx],
								v
							)
						} else if (this.paymentLimit[v].LIMIT_CHECK === 'B') {
							// 은행허용
							this.regularPaymentSelected(
								this.regularPaymentList[paymentBankLastIdx],
								v
							)
						} else {
							// 제한없음
							this.regularPaymentSelected(
								this.regularPaymentList[paymentLastIdx],
								v
							)
						}
					})
				}
			}

			// 정기결제 추가시 수납용으로 사용 가능한 경우 수납용으로도 추가
			// 수납용으로 추가하는 경우 일회성 결제라 결제일을 알 수 없어 정기 결제로 세팅하지 않음
			if (type === 'ADD') {
				if (index === -1) {
					// 수납 결제
					this.receivePaymentList.forEach((v, i) => {
						if (v.AF_CARD_NO === item) {
							// 추가한 카드번호의 정보를 선택을 해준다.
							this.receivePaymentSelected(this.receivePaymentList[i])
						}
					})
				} else {
					// 정기결제
					this.regularPaymentList.forEach((v, i) => {
						if (v.AF_CARD_NO === item) {
							this.regularPaymentSelected(this.regularPaymentList[i], index)

							// 수납으로 사용 가능한 결제수단의 경우 선택
							this.receivePaymentCheck(params.payMthd, params.code).then(
								res => {
									if (res) {
										// 추가한 카드번호의 정보를 선택을 해준다.
										this.receivePaymentSelected(this.regularPaymentList[i])
									} else {
										this.$log('테스트', res)
									}
								}
							)
						}
					})
				}
			}
		},

		// 수납 결제수단용으로 사용이 가능한지 여부 체크
		async receivePaymentCheck(payMthd, bankCd) {
			const params = { grpCd: 'G6' }
			const paymentListCheck = await paymentList(params)

			//수납으로 사용 가능한 코드
			const onceCd = []
			paymentListCheck.resultObject
				.filter(x => x.receiptYn === 'Y')
				.forEach(v => {
					onceCd.push(v.cd)
				})

			// 카드 또는 은행중 수납 가능한 은행
			if (payMthd === '12') {
				return true
			} else if (payMthd === '11') {
				return onceCd.includes(bankCd)
			}
		},

		// 이전
		async back() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return

			let arrParam = {
				id: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
				statusCode: '104'
			}
			const res = await mOrderInfoByEtc(arrParam)
			let msg = undefined
			msg = getMessageByStatusCode(res.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							dept_cd: this.$store.getters['common/deptCd'],
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']
				const res = await fetchWebOrderInfoById(mobOrderNo)
				let webParams = res.resultObject
				webParams.step = '03' //결제정보
				webParams.statusCode = '104' //결제입력
				postWebOrderInfoById(mobOrderNo, webParams)
				this.$router.push({
					name: 'order-pay',
					query: { status: '105' }
				})
			}
		},

		regularNoDefaultSetting() {
			const prdListTemp = this.$store.getters['pay/payProducts']

			this.regularSelectPayment = []
			this.regularDisplayText = []
			this.regularPaymentType = []
			this.regularNo = []

			// 정기 결제 금액이 있는 상품들만 추출하여 prdList에 추가
			prdListTemp.forEach((v, i) => {
				if (this.payOrders[i].MONTH_AMT !== 0) {
					// 기본값 생성(빈값)
					this.regularNo.push('')
					this.regularPaymentType.push('')
				}
			})
		},

		/**
		 * 공통적용/ 개발적용 라디오 바꿀대 선택된 정보 초기화 하고 디폴트값 다시 설정
		 */
		selectEmpty() {
			this.regularSelectPayment = []
			this.regularDisplayText = []
			this.regularPaymentType = []
			this.regularNo = []

			this.regularNoDefaultSetting()

			this.defaultPaymentSetting()
		},

		// 다음 - 설치정보 이동
		async goRecp() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']
			if (this.isParameterEmpty(mobOrderNo)) return

			let arrParam = {
				id: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
				statusCode: '107'
			}
			const res = await mOrderInfoByEtc(arrParam)
			let msg = undefined
			msg = getMessageByStatusCode(res.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							dept_cd: this.$store.getters['common/deptCd'],
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				// 사전계약 관리 판매인 체크
				const preContractSaleCheck = await this.preContractSaleChk()
				if (!preContractSaleCheck) return

				// 정기결제 금액이 있을 경우 체크
				if (this.totalAmt.totalSumOfMonthAmt > 0) {
					// 공통적용, 졍기결제 선택 체크
					if (
						this.group === '1' &&
						(this.regularPaymentType.length === 0 ||
							this.regularPaymentType[0] === '' ||
							this.regularPaymentType[0] === undefined)
					) {
						this.$alert({ message: '정기결제 수단을 선택해 주세요.' })
						return
					}

					// 개별적용, 정기결제 선택 체크
					if (this.group === '2') {
						if (
							this.regularPaymentType.findIndex(
								x => x === undefined || x === ''
							) !== -1
						) {
							const i = this.regularPaymentType.findIndex(
								x => x === undefined || x === ''
							)
							if (
								this.paymentLimit[i].LIMIT_CHECK &&
								this.paymentLimit[i].LIMIT_CHECK === 'X'
							) {
								this.$alert({
									message: `주문${i +
										1}의 조직 연체율로 인해 자체할부 주문이 불가합니다. 해당 주문은 이전 단계로 되돌아가 삭제하시기 바랍니다.`
								})
								return
							} else {
								this.$alert({
									message: `주문${i + 1}의 정기결제 수단을 선택해 주세요.`
								})
								return
							}
						}
					}

					const expireChk = this.preContractOwnerYmChk()
					if (!expireChk) return
				}

				// 수납 금액이 있을 경우 체크
				if (this.totalAmt.totalSumOfRecpAmt > 0) {
					if (
						this.receiveSelectPayment.cardn === undefined &&
						this.receiveSelectPayment.suacc === undefined
					) {
						this.$alert({ message: '수납결제 수단을 선택해 주세요.' })
						return
					}

					let res = ''
					if (this.receiveSelectPayment.payMthd == '12') {
						res = await cardTransfer(this.receiveSelectPayment)
					} else if (this.receiveSelectPayment.payMthd == '11') {
						res = await bankInstantTransfer(this.receiveSelectPayment)
					}
					if (res.resultObject.data.E_RETURN.TYPE === 'S') {
						await this.$alert({
							message:
								'수납금 결제는 전자 계약서 서명 완료 후 진행됩니다.<br />설치 정보 화면으로 이동합니다.',
							okText: '설치정보 화면으로 이동'
						})
						if (
							this.receiveSelectPayment.cardn !== undefined ||
							this.receiveSelectPayment.suacc !== undefined
						) {
							this.$store.dispatch('recp/setRecpParams', {
								...this.receiveSelectPayment
							})
							this.$store.dispatch('recp/setPayRecp', { ...this.payRecp })
						}

						if (this.receiveSelectPayment.payMthd == '12') {
							this.tranceRtn = res.resultObject.data.TC_CARD[0]
						} else {
							this.tranceRtn = res.resultObject.data.TC_BKACCT[0]
						}
					} else {
						const options = {
							title: '다음과 같은 사유로 수납이 거부됐습니다.',
							guide: '수납방법을 재선택 바랍니다.',
							contents: {
								거부사유: res.resultObject.data.E_RETURN.MESSAGE
							},
							okText: '수납방법 재선택',
							cancelText: ''
						}

						await this.$refs.recpPop.open(options)
						return
					}
				}

				//정기결제 정보 수납방법 저장하는 로직
				const po = this.$store.getters['pay/paymentOrders']
				let itPay = []

				if (this.group === '2') {
					let i = 0
					itPay = po.map(p => {
						const info = this.regularSelectPayment[i]
						if (p.MONTH_AMT > 0) i++
						return {
							...p,
							...(p.KATR2 === '1' ? { SAFEKEY: '' } : { SAFEKEY: p.SAFEKEY }),
							...(p.MONTH_AMT > 0 ? info : '')
						}
					})
				} else {
					itPay = po.map(p => {
						return {
							...p,
							...(p.KATR2 === '1' ? { SAFEKEY: '' } : { SAFEKEY: p.SAFEKEY }),
							...(p.MONTH_AMT !== 0 ? this.regularSelectPayment[0] : ''),
							...(p.MONTH_AMT !== 0 ? this.regularSelectPayment[0] : '')
						}
					})
				}
				this.$store.dispatch('pay/savePaymentForOrder', itPay)

				let ssn = this.$store.getters['verify/ssn']
				if (ssn) {
					ssn = ssn.substr(2, ssn.length)
					let custType = this.$store.getters['verify/customerType']
					if (custType === 'L') {
						ssn = this.$store.getters['verify/bizNo']
					}
				}
				let name = this.$store.getters['verify/custName']
				let custType = this.$store.state['verify'].customer.custType
				this.recpInfos.name = name
				this.recpInfos.mthd = this.receiveSelectPayment.payMthd
				this.recpInfos.custType =
					custType === 'P' ? '1' : custType === 'L' ? '2' : custType
				this.recpInfos.ssn = ssn
				this.recpInfos.amount = this.totalAmt.totalSumOfRecpAmt
				this.recpInfos.kunnr = this.$store.state['verify'].customer.custNo
				this.recpInfos.userId = this.$store.getters['common/codyNo']
				this.recpInfos.phone = this.$store.getters['verify/custPhone']
				this.recpInfos.zwebRecp = '04' //04:판매인 07:고객수납
				this.$store.dispatch('recp/setRecpInfos', this.recpInfos)

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']
				const result = await fetchWebOrderInfoById(mobOrderNo)
				let webParams = result.resultObject
				let order = JSON.parse(webParams.orderInfoJson)
				this.webOrderSum = order.PAY_SUMMARY_AMT

				//카드
				if (
					this.regularSelectPayment[0]?.PAY_MTHD === '12' ||
					this.receiveSelectPayment.payMthd === '12'
				) {
					order = Object.assign(order, {
						recp: {
							recpParams: this.$store.getters['recp/recpParams'],
							recpInfos: this.$store.getters['recp/recpInfos'],
							isCustSelfPay: false,

							recpCardResult: this.tranceRtn,
							payRecp: this.$store.getters['recp/payRecp']
						},
						IT_PAYMENT: itPay
					})
				} else if (
					this.regularSelectPayment[0]?.PAY_MTHD === '11' ||
					this.receiveSelectPayment.payMthd === '11'
				) {
					order = Object.assign(order, {
						recp: {
							recpParams: this.$store.getters['recp/recpParams'],
							recpInfos: this.$store.getters['recp/recpInfos'],
							isCustSelfPay: false,
							recpBankResult: this.tranceRtn,

							payRecp: this.$store.getters['recp/payRecp']
						},
						IT_PAYMENT: itPay
					})
				}

				webParams.step = '05' // 설치정보
				webParams.statusCode = '107' // 설치대기
				webParams.orderInfoJson = JSON.stringify(order)

				await postWebOrderInfoById(mobOrderNo, webParams)
				// 설치정보
				this.$router.push({ name: 'order-inst' })
			}
		},

		/**
		 * 고객 추가 정보가 있을시 기본 셋팅
		 */
		async defaultPaymentSetting() {
			let paymentEnable = 0

			if (
				this.regularPaymentList.length > 0 ||
				this.receivePaymentList.length > 0
			) {
				// 정기결제 선택
				const regularMainPayment = this.regularPaymentList.find(
					x => x.MAIN_PAYMENT === true
				)

				if (regularMainPayment && regularMainPayment.MAIN_PAYMENT === true) {
					const afCardNo = regularMainPayment.AF_CARD_NO
					await this.paymentLimit.forEach((v, i) => {
						if (v.LIMIT_CHECK === '') {
							// 모두 허용
							this.regularNo[i] = afCardNo
							this.regularPaymentSelected(regularMainPayment, i)
							paymentEnable += 1
						} else if (
							v.LIMIT_CHECK === 'C' &&
							regularMainPayment.AF_PAY_MTHD === '12'
						) {
							// 카드허용
							this.regularNo[i] = afCardNo
							this.regularPaymentSelected(regularMainPayment, i)
							paymentEnable += 1
						} else if (
							v.LIMIT_CHECK === 'B' &&
							regularMainPayment.AF_PAY_MTHD === '11'
						) {
							// 은행허용
							this.regularNo[i] = afCardNo
							this.regularPaymentSelected(regularMainPayment, i)
							paymentEnable += 1
						}
					})
				}

				// 수납결제 선택
				const receiveMainPayment = this.receivePaymentList.find(
					x => x.MAIN_PAYMENT === true && x.PAY_TYPE === 'O'
				)

				if (receiveMainPayment) {
					this.receiveNo = receiveMainPayment.AF_CARD_NO
					this.receivePaymentSelected(receiveMainPayment)
				}

				const productCount = this.prdList.length
				// 대표결제 수단이 1개라도 있을 경우 Alert  출력
				if (receiveMainPayment || regularMainPayment) {
					if (productCount === paymentEnable && receiveMainPayment) {
						// 상품수와 정기결제에 세팅된 수가 동일하고, 수납결제 수단도 세팅이 되면
						// MAIN_PAYMENT가 있고, 상품의 갯수와 자동세팅한 갯수가 동일할 경우
						await this.$alert({
							message:
								'고객이 등록한 결제정보가 있습니다. 해당 정보로 기본 설정됩니다.'
						})
						this.preContractOwnerYmChk()
					} else {
						// 그외 일부만 세팅이 되면
						// MAIN_PAYMENT가 있고, 1개라도 자동세팅이 불가할 경우
						await this.$alert({
							message:
								'고객이 등록한 결제정보 중 사용 가능한 주문 건에만 기본 설정 됩니다.'
						})
						this.preContractOwnerYmChk()
					}
				}
			}
		},
		// 사전계약 카드 유효기간 > 도래월 체크
		preContractOwnerYmChk() {
			if (
				this.isPreContract &&
				this.regularSelectPayment &&
				this.regularSelectPayment.length > 0
			) {
				const regularSelectPaymentList =
					this.group === '1'
						? [this.regularSelectPayment[0]]
						: this.regularSelectPayment

				let failOrderNum = ''
				regularSelectPaymentList.map((item, index) => {
					if (
						item !== undefined &&
						item.PAY_MTHD === '12' &&
						moment(item.EXPIRE_YM.concat('01')).isBefore(
							moment(this.deliveryInfos[0].preOwnerYm.concat('02'))
						)
					) {
						failOrderNum =
							failOrderNum.length === 0
								? '주문'.concat(index + 1)
								: failOrderNum.concat(',주문', index + 1)

						this.$set(this.regularDisplayText, index, '정기결제 수단 선택')
						this.$set(this.regularSelectPayment, index, undefined)
						this.$set(this.regularPaymentType, index, undefined)
						this.$set(this.regularNo, index, undefined)
					}
				})

				if (failOrderNum.length > 0) {
					let msg = this.group === '1' ? '' : `${failOrderNum}의 `
					msg +=
						'선택한 카드의 유효기간이 소유권도래월 이전이라 선택 불가합니다. '
					this.$alert({
						message: msg
					})
					return false
				} else {
					return true
				}
			}
			return true
		},
		// 사전 계약 관리판매인 변경 체크
		async preContractSaleChk() {
			if (!this.isPreContract) return true // 사전계약이 아니면 Pass

			const preOrderList = []
			const products = this.$store.getters['pay/payProducts']
			products.forEach(product => {
				if (product.options.preOrder !== undefined)
					preOrderList.push(product.options.preOrder.ORDER_NO)
			})

			if (preOrderList.length > 0) {
				return await getPreContractSalesChk({
					saleCd: this.$store.getters['common/codyNo'],
					orderNos: [...preOrderList],
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				})
					.then(async res => {
						const { result } = res.resultObject
						if (result !== 'T') {
							// 주문 삭제
							await this.$alert({
								message:
									'선택된 기존계정의 관리판매인이 변경된 것으로 확인되어 해당 주문이 삭제되었습니다. '
							})
							const mobOrderNo = this.$store.getters['verify/mobOrderNo']

							const res = await fetchWebOrderInfoById(mobOrderNo)
							const webParams = res.resultObject
							webParams.statusCode = '203'

							postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
								this.$log('postRes: ', JSON.stringify(postRes))
								// 모바일 주문현황으로 이동
								this.$router.push({ name: 'order-status' })
							})
							return false
						} else {
							return true
						}
					})
					.catch(err => {
						this.$log(err)
						this.$alert(
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
						)
					})
			}

			return true
		}
	}
}
</script>
